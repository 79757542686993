import React, { useState } from 'react';

const Medal = ({ medal }) => {
    return (
        <tr>
            <td>{medal.Ribbon && <img src={medal.Ribbon} alt="Medal ribbon" style={{ width: '50px' }} />}</td>
            <td>{medal.AwardName}</td>
            <td>{medal.PostNominal}</td>
            <td>{medal.AdministeredBy}</td>
            <td>{medal.Notes}</td>
            <td>{medal.YearReceived}</td>
        </tr>
    );
}

export default Medal;
