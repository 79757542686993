import React, { useState, useEffect } from 'react';
import fetchMedals from '../../utils/fetchMedals';
import foreignMedalsData from '../../utils/foreignMedals.json'; // Path to your JSON file
import Medal from '../Medal';
import './index.scss';

const MedalList = () => {
    const [australianMedals, setAustralianMedals] = useState([]);
    const [foreignMedalsList] = useState(
        foreignMedalsData.foreignAwards.map(medal => ({
            AwardName: medal,
            Ribbon: null,
            PostNominal: '',
			// Set the AdministeredBy to the second and third character in the retrieved award name, as it is stored as `[Country Code] Award Name` in the JSON file
			AdministeredBy: medal.substring(1, 3),
            Notes: 'Foreign',
            Type: 'Foreign',
            YearReceived: ''  // Assuming year received will be added later
        }))
    );
    const [selectedMedals, setSelectedMedals] = useState([]);
    const [selectedAustralianMedalIndex, setSelectedAustralianMedalIndex] = useState('');
    const [selectedForeignMedalIndex, setSelectedForeignMedalIndex] = useState('');
    const [year, setYear] = useState('');

    useEffect(() => {
        const getMedals = async () => {
            const fetchedMedals = await fetchMedals();
            setAustralianMedals(fetchedMedals.map((medal, index) => ({ ...medal, originalIndex: index })));  // Storing the original index
        };

        getMedals();
    }, []);

    const handleSelectAustralianMedal = e => {
        setSelectedAustralianMedalIndex(e.target.value);
    };

    const handleSelectForeignMedal = e => {
        setSelectedForeignMedalIndex(e.target.value);
    };

    const addMedal = () => {
		if (year && (selectedAustralianMedalIndex !== '' || selectedForeignMedalIndex !== '')) {
			let medalToAdd = null;
			if (selectedAustralianMedalIndex !== '') {
				medalToAdd = australianMedals[selectedAustralianMedalIndex];
			} else if (selectedForeignMedalIndex !== '') {
				medalToAdd = foreignMedalsList[selectedForeignMedalIndex];
			}
	
			if (medalToAdd && !selectedMedals.some(m => m.AwardName === medalToAdd.AwardName)) {
				// Remove the country code from the award name (if it exists, australian ones don't have it, but international ones are formatted as `[Country Code] Award Name` and it must check that the first character is `[`, if it is then do the split and slice) but save the medal as a different variable so it does not affect the original data
				medalToAdd = { ...medalToAdd, AwardName: medalToAdd.AwardName[0] === '[' ? medalToAdd.AwardName.split('] ')[1] : medalToAdd.AwardName };
				setSelectedMedals([...selectedMedals, { ...medalToAdd, YearReceived: year }]);
				setYear('');
				setSelectedAustralianMedalIndex('');  // Resetting the index after adding
				setSelectedForeignMedalIndex('');  // Resetting the index after adding
			}
		}
	};	

    // Sorting where Australian medals stay in their fetched order, and foreign medals are sorted by year
    const sortedMedals = selectedMedals.sort((a, b) => {
        if (a.Type !== b.Type) {
            return a.Type === 'Australian' ? -1 : 1;
        }
        if (a.Type === 'Foreign') {
            return parseInt(a.YearReceived) - parseInt(b.YearReceived);
        }
        if (a.Type === 'Australian' && b.Type === 'Australian') {
            return a.originalIndex - b.originalIndex;
        }
        return 0;
    });

    return (
        <div className="medals-container">
            <div className="medals-list">
                <h2 className="medals-title">The Order of Wearing Australian Honours and Awards</h2>
                <select value={selectedAustralianMedalIndex} onChange={handleSelectAustralianMedal}>
                    <option value="">Select an Australian Medal</option>
                    {australianMedals.map((medal, index) => (
                        <option key={index} value={index}>{medal.AwardName}</option>
                    ))}
                </select>
                <select value={selectedForeignMedalIndex} onChange={handleSelectForeignMedal}>
                    <option value="">Select a Foreign Medal</option>
                    {foreignMedalsList.map((medal, index) => (
                        <option key={index} value={index}>{medal.AwardName}</option>
                    ))}
                </select>
                <input 
                    type="number" 
                    placeholder="Year Received" 
                    value={year} 
                    onChange={e => setYear(e.target.value)}
                />
                <button onClick={addMedal}>Add Medal</button>
                <table className="medals-table">
                    <thead>
                        <tr>
                            <th>Ribbon</th>
                            <th>Award Name</th>
                            <th>Post Nominal</th>
                            <th>Administered By</th>
                            <th>Notes</th>
                            <th>Year Received</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedMedals.map((medal, index) => (
                            <Medal key={index} medal={medal} />
                        ))}
                    </tbody>
                </table>
            </div>

			<div className="medals-notes">
				<h3>Notes</h3>
				<br />
				Medals are sorted by the order they appear on the <a href="https://www.defence.gov.au/adf-members-families/honours-awards/resources-policy/policy/order-wearing-honours-awards/order-wearing-australian-honours-awards" target="_blank" rel="noreferrer">Australian Government Department of Defence</a> website.
				<br />
				Foreign medals are sorted by the year they were received.
				<ol>
					<li>Refers to the Imperial Victoria Cross and the Victoria Cross for Australia.</li>
					<li>Provision for further awards at this level within the Order of Australia was removed by Her Majesty The Queen on 3 March 1986 on the advice of the Prime Minister.</li>
					<li>Listed to indicate where any awards within the Order of St John should be worn; however, the Service Medal of the Order of St John should be worn as a Long Service Medal after all other Imperial Long Service awards. Post nominals within the Order of St John are not recognised as notified in the Governor-General’s media release of 14 August 1982. (The Life Saving Medal is worn on the right side.)</li>
					<li>The Australian Antarctic Medal was known as the Antarctic Medal until 18 December 1997.</li>
					<li>Recipients of the 1914 Star are not eligible for the award of the 1914–1915 Star.</li>
					<li>The order of wearing of the Naval General Service Medal 1915–1962 and General Service Medal 1918–1962 (Army and Air Force) will vary from person to person depending on when the person earned the first clasp. If the first clasp relates to service between World War I and World War II, the medals should be worn immediately after World War I war medals. If the first clasp relates to service after 2 September 1945, the medals should be worn immediately after the United Nations Service Medal for Korea.</li>
					<li>Clasps to these medals should be worn on the ribbon in order of date of receipt.</li>
					<li>Only one of these three Stars could be awarded to an individual. Should a person have qualified for two of these awards, the Star first earned is worn with the clasp of the second Star. Only one Star and one clasp may be worn even if the person qualified for all three Stars.</li>
					<li>Only one of these two Stars could be awarded to an individual. Should a person have qualified for both the Pacific Star and the Burma Star, the Star first earned was awarded together with the appropriate clasp denoting the service that would have qualified for the other Star.</li>
					<li>Uniquely, although a foreign award, the United Nations Service Medal for Korea is worn immediately after the Korea Medal. All other foreign awards for which official permission has been given to accept and wear are worn as foreign awards.</li>
					<li>A person who has been awarded the Vietnam Medal, or who is eligible for the award of the Vietnam Medal, is not eligible for the award of the Vietnam Logistic and Support Medal.</li>
					<li>Worn in order of date of qualifying service.</li>
					<li>Refers to Imperial efficiency and long service awards.</li>
				</ol>
			</div>
        </div>
    );
};

export default MedalList;
