import React from 'react';
import './App.scss'; // Ensure any global styles are imported
import MedalList from './components/MedalList'; // Adjust path as necessary
import Header from './components/Header'; // Adjust path as necessary

function App() {
  return (
    <div className="App">
      <Header />
      <MedalList />
    </div>
  );
}

export default App;
