import axios from "axios";
import cheerio from "cheerio";

const fetchMedals = async () => {
    const developmentUrl = "http://localhost:3004/medals";  // Your local development URL
    const productionUrl = "https://aopha.net/utils/api/fetch_medals.php";  // Adjust this to the actual URL where your PHP script is hosted

    const url = process.env.NODE_ENV === 'development' ? developmentUrl : productionUrl;

    const exclusions = [
        "award",
        "foreign"
    ];

    try {
        const response = await axios.get(url);
        const $ = cheerio.load(response.data);

        const australianMedals = [];

        $("tr").each((index, element) => {
            const cells = $(element).find("td");
            if (cells.length > 1) {
                let awardName = $(cells[1]).text().trim().toLowerCase(); // Convert fetched award name to lowercase for comparison
                if (!exclusions.includes(awardName)) { // Check if the award is not in the exclusions list using lowercase comparison
                    let ribbonSrc = $(cells[0]).find("img").attr("src");
                    ribbonSrc = ribbonSrc && !ribbonSrc.startsWith("http") ? "https://www.defence.gov.au" + ribbonSrc : ribbonSrc;
                    australianMedals.push({
                        Ribbon: ribbonSrc || null,
                        AwardName: $(cells[1]).text().trim(), // Store the original formatting of the name
                        PostNominal: $(cells[2]).text().trim(),
                        AdministeredBy: $(cells[3]).text().trim(),
                        Notes: $(cells[4]).text().trim(),
                        Type: "Australian"
                    });
                }
            }
        });

        return australianMedals;
    } catch (error) {
        console.error("Failed to fetch medals:", error);
        return [];
    }
};

export default fetchMedals;
