import React from 'react';
import './index.scss';
import Logo from '../../assets/logo2.svg';

const Header = () => {
    return (
        <header className="app-header">
			<div className="header-container">
				<a href="https://aopha.net" className="logo-container">
					<img src={Logo} alt="AOPHA Logo" className="logo" />
				</a>
				<h3 className="header-title">Unofficial</h3> {/* Can replace this with `Defence` */}
			</div>
        </header>
    );
}

export default Header;
